<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/admin/AddAdmin"
      finish-router="admin"
      permission="Admin_Create"
    >
    </edit-field>
  </page-content>
</template>

<script>
import fields from "@/views/admin/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import common from "@/common";


export default {
  name: "Add",
  components: {
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('admin') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {}
    }
  }
}
</script>

<style scoped>

</style>
