import common from "@/common";

export default [
  {
    name: 'adminCode',
    label: 'admin.adminCode',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'loginName',
    label: 'admin.loginName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'initPassword',
    label: 'admin.initPassword',
    type: 'text',
    rule: 'required|max: 20',
    add_show: true
  },
  {
    name: 'chineseName',
    label: 'admin.chineseName',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'englishName',
    label: 'admin.englishName',
    type: 'text',
    rule: 'required|max: 50',
  },
  {
    name: 'contactEmail',
    label: 'admin.contactEmail',
    type: 'text',
    rule: 'max: 50|email',
  },
  {
    name: 'contactPhone',
    label: 'admin.contactPhone',
    type: 'text',
    rule: 'max: 20|digits: 8',
  },
  {
    name: 'isActive',
    label: 'admin.isActive',
    type: 'select',
    rule: 'required',
    edit_show: true,
    options: [
      {text: common.getI18n('staff.active'), value: true},
      {text: common.getI18n('staff.passive'), value: false},
    ],
  },
  {
    name: 'isSystemAdmin',
    label: 'admin.isSystemAdmin',
    type: 'select',
    rule: 'required',
    show: false,
    show_exp: 'this.common.getUserProfile().isSA && !this.isEdit',
    options: [
      {text: common.getI18n('common.yes'), value: true},
      {text: common.getI18n('common.no'), value: false},
    ],
  },

]
